import { Typography } from "@mui/material";
import PolicyCheckerAgGrid from "../ag-grid/PolicyCheckerAgGrid";
import PropTypes from "prop-types";

/**
 * A container component that wraps PolicyCheckerAgGrid with additional styling and a title.
 * Provides flexible height management and consistent styling across the application.
 *
 * @component
 * @param {Object} props
 * @param {string} [props.title] - Optional title displayed above the grid
 * @param {Array} props.columnDefs - Column definitions for the grid
 * @param {Array} props.rowData - Data to be displayed in the grid
 * @param {Function} props.onRowClicked - Callback function triggered when a row is clicked
 * @param {string} [props.height='300px'] - Container height (only used when domLayout is not 'autoHeight')
 * @param {string} [props.domLayout='autoHeight'] - Grid layout mode: 'autoHeight' adjusts to content, 'normal' uses container height
 * @returns {React.Component} A styled container with title and AG Grid
 *
 * @example
 * // Auto-height grid with title
 * <GridContainer
 *   title="My Grid"
 *   columnDefs={columns}
 *   rowData={data}
 *   onRowClicked={handleRowClick}
 * />
 *
 * // Fixed height grid
 * <GridContainer
 *   title="Fixed Height Grid"
 *   columnDefs={columns}
 *   rowData={data}
 *   onRowClicked={handleRowClick}
 *   domLayout="normal"
 *   height="500px"
 * />
 */
const GridContainer = ({
  title = "",
  columnDefs,
  rowData,
  onRowClicked,
  height = "300px",
  domLayout = "autoHeight",
  onRowDataUpdated,
  gridRef,
  loading,
}) => {
  const containerStyle = {
    borderRadius: "5px",
    margin: "0 auto",
    padding: "15px",
    height: !rowData || rowData.length === 0 ? "200px" : domLayout !== "autoHeight" ? height || "300px" : undefined,
  };

  let usabledDomLayout = domLayout;

  if (rowData.length === 0) {
    containerStyle.height = "200px";
    usabledDomLayout = "normal";
  }

  return (
    <div className={"ag-theme-quartz"} style={containerStyle}>
      <Typography sx={{ textAlign: "left", marginBottom: "10px" }}>{title}</Typography>
      <PolicyCheckerAgGrid
        loading={loading}
        gridRef={gridRef}
        columnDefs={columnDefs}
        rowData={rowData}
        onRowClicked={onRowClicked}
        onRowDataUpdated={onRowDataUpdated}
        domLayout={usabledDomLayout}
      />
    </div>
  );
};

GridContainer.propTypes = {
  title: PropTypes.string,
  gridRef: PropTypes.object,
  columnDefs: PropTypes.array.isRequired,
  rowData: PropTypes.array.isRequired,
  onRowClicked: PropTypes.func.isRequired,
  onRowDataUpdated: PropTypes.func,
  height: PropTypes.string,
  domLayout: PropTypes.string,
  loading: PropTypes.bool,
};

export default GridContainer;
