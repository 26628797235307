import PdfViewer from "../components/PdfViewer";
import GridContainer from "../components/GridContainer";
import InformationContainer from "../components/InformationContainer";
import React, { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useResultsPageColumnDefs from "../customHooks/columnDefs/useResultsPageColumnDefs";
import useResultsById from "../customHooks/useResults";
import { useParams } from "react-router-dom";
import useFilesByComparison from "../customHooks/useFilesByComparison";
import useJobDetailsData from "../customHooks/useJobDetailsData";
import OverrideModal from "../components/OverrideModal";
import useAuthenticatedPage from "../customHooks/useAuthenticatedPage";
import useComparisonCompleteMutator from "../customHooks/useComparisonCompleteMutator";
import ConfirmationModal from "../components/ConfirmationModal";
import "../styles/resultspage.css";

/**
 * @constant {string[]} INVALID_STATES - The states indicating the results status.
 * @default ["Results Reviewed", "Completed"]
 */
const INVALID_STATES = ["Results Reviewed", "Completed"];

/**
 * Results component displays the comparison results between two PDF documents.
 *
 * This component provides a comprehensive view of policy comparison results including:
 * - Information about the job and client
 * - Side-by-side PDF viewers for both documents with highlighted differences
 * - A breakdown grid showing detailed comparison results with sorted data:
 *   - Primary sort by page number (null values appear last)
 *   - Secondary sort by checkpoint name (alphabetically) when page numbers are equal
 * - Override functionality for managing discrepancies
 * - Completion functionality to mark the comparison as complete
 *
 * The component uses URL parameters to fetch specific job and comparison data:
 * @param {string} jobId - URL parameter for the job identifier
 * @param {string} comparisonId - URL parameter for the comparison identifier
 *
 * Features:
 * - Displays client and job information in an information panel
 * - Shows two PDF viewers side by side with synchronized navigation
 * - Highlights relevant text in PDFs based on comparison results
 * - Provides a detailed breakdown grid of all comparison points
 * - Supports override functionality for managing discrepancies
 * - Includes a complete button for finalizing and marking the comparison as complete
 * - Implements smart sorting:
 *   - Results are ordered by page number in ascending order
 *   - Results with null page numbers are displayed last
 *   - When page numbers match, results are sorted alphabetically by checkpoint name
 *
 * State Management:
 * - Manages selected result state for PDF highlighting
 * - Handles override modal state for discrepancy management
 * - Manages comparison completion state through mutation
 *
 * @returns {JSX.Element} The rendered Results component
 */
const Results = () => {
  const { jobId, comparisonId } = useParams();
  const { data: results, isLoading } = useResultsById(comparisonId);
  const { data: files } = useFilesByComparison(comparisonId);
  const { data: jobDetails } = useJobDetailsData(jobId);
  const { shouldBlockPage } = useAuthenticatedPage();

  const gridRef = useRef(null);

  const [selectedResult, setSelectedResult] = useState(null);
  const [overrideData, setOverrideData] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [topVisibleRowNode, setTopVisibleRowNode] = useState(null);

  const navigate = useNavigate();
  const completeMutation = useComparisonCompleteMutator();

  const currentStatus =
    jobDetails?.comparisons?.find((i) => i.comparison_id === parseInt(comparisonId))?.comparison_status || "";

  const fileDescriptions =
    results?.scenario_file_names || jobDetails?.files?.map((file) => file.file_description) || [];

  const usableResults = results || { comparisons: [] };

  const resultsPageColumnDefs = useResultsPageColumnDefs(
    setOverrideData,
    INVALID_STATES.includes(currentStatus),
    fileDescriptions
  );

  const handleRowClick = (e) => {
    const result = e.data;
    if (!files[0] || !files[1]) return;
    setSelectedResult(result);
  };

  const thisComparison = jobDetails?.comparisons?.find((c) => c.comparison_id.toString() === comparisonId);

  const handleComplete = () => {
    setIsConfirmationOpen(true);
  };

  const handleConfirmComplete = () => {
    completeMutation.mutate(comparisonId, {
      onSuccess: () => {
        setTimeout(() => {
          setIsConfirmationOpen(false);

          navigate(`/jobdetails/${jobId}`);
        }, 1000);
      },
    });
  };

  const onOverrideChange = (newVal) => {
    if (gridRef.current?.api) {
      setTopVisibleRowNode(overrideData.results_id);
    }
    setOverrideData(newVal);
  };

  useEffect(() => {
    if (results?.length > 0 && files?.length >= 2) {
      const firstResult = results.comparisons[0];
      if (firstResult.first_file_checkpoint_value || firstResult.second_file_checkpoint_value) {
        setSelectedResult(firstResult);
      }
    }
  }, [results, files]);
  function restoreScrollPositionAfterDataLoad() {
    if (topVisibleRowNode && !isLoading && gridRef.current?.api) {
      requestAnimationFrame(() => {
        const matchedIndex = results.comparisons.findIndex((item) => item.results_id === topVisibleRowNode);
        console.log("ensureIndexVisible", matchedIndex, " for id ", topVisibleRowNode);
        gridRef.current.api.ensureIndexVisible(matchedIndex);
        // setTopVisibleRowNode(null);
      });
    }
  }

  useEffect(restoreScrollPositionAfterDataLoad, [topVisibleRowNode, results, isLoading]);

  if (shouldBlockPage) {
    return <div>Invalid access</div>;
  }

  return (
    <div className="results-container">
      <div className="information-container">
        {results && results.comparisons && results.comparisons.length > 0 && (
          <InformationContainer
            clientCode={jobDetails?.files?.[0]?.client_code || "N/A"}
            scenarioName={jobDetails?.scenario_name || "N/A"}
            lineOfBusiness={jobDetails?.files?.[0]?.line_of_business || "N/A"}
            jobId={jobId || "N/A"}
            client={jobDetails?.account_name || "N/A"}
            comparisonId={comparisonId || "N/A"}
            comparisonName={thisComparison?.comparison_name || files?.[0]?.file_name || "N/A"}
          />
        )}
      </div>
      <div className="pdf-container">
        {files && files.length && (
          <div key="pdf-container-left" className={`pdf-viewer-container firstDocument_${0}`}>
            <h3>{files[0].file_name}</h3>
            {files[0].file_content && (
              <PdfViewer
                pdfUrl={files[0].file_content}
                searchTerm={selectedResult?.first_file_checkpoint_value}
                targetPages={selectedResult?.first_file_checkpoint_page_no - 1}
                isMatch={selectedResult?.checkpoint_result}
              />
            )}
          </div>
        )}
        {files && files[1] && (
          <div key="pdf-container-right" className={`pdf-viewer-container firstDocument_${1}`}>
            <h3>{files[1].file_name}</h3>
            {files[1].file_content && (
              <PdfViewer
                pdfUrl={files[1].file_content}
                searchTerm={selectedResult?.second_file_checkpoint_value}
                targetPages={selectedResult?.second_file_checkpoint_page_no - 1}
                isMatch={selectedResult?.checkpoint_result}
              />
            )}
          </div>
        )}
      </div>
      <GridContainer
        title="Breakdown"
        gridRef={gridRef}
        loading={isLoading}
        columnDefs={resultsPageColumnDefs}
        rowData={(usableResults.comparisons || []).sort((a, b) => {
          return a.results_id > b.results_id ? 1 : -1;
        })}
        onRowClicked={handleRowClick}
        onRowDataUpdated={restoreScrollPositionAfterDataLoad}
        domLayout="normal"
      />
      <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleComplete}
          disabled={completeMutation.isLoading || INVALID_STATES.includes(currentStatus)}
        >
          Complete
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            navigate(`/jobdetails/${jobId}`);
          }}
        >
          Close
        </Button>
      </div>
      <OverrideModal overrideData={overrideData} setOverrideData={onOverrideChange} />
      <ConfirmationModal
        open={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={handleConfirmComplete}
        title="Complete Review"
        message="This will complete the review process and block any further changes. Are you sure?"
      />
    </div>
  );
};

export default Results;
